/* eslint-disable no-undef */
/**
 * Use this form to import ACL permissions from a file.
 * import {Roles, HasRoles} from 'common/ACL';
 */
const roles = {
	analystN1: 'analyst_n1_user_experience',
	analystN2: 'analyst_n2_user_experience',
	quality: 'quality_user_experience',
	supervisor: 'supervisor_user_experience',
	supervisor_night: 'supervisor_midnight_user_experience',
	internal_itsm_acl: 'internal_itsm_acl',
	internal_operations: 'internal_operations',
	fraud: 'fraud_prevention',
	street: 'street_operations',
	office: 'office_operations',
	financial: 'financial',
	people: 'people',
	marketing: 'marketing',
	tech: 'tech',
	iot: 'iot',
	heads: 'heads',
	supervisorMidnight: 'supervisor_midnight_user_experience',
	wash: 'internal_wash',
	sellers: 'internal_sellers',
};

export const RoleGroups = {
  risk: [
    roles.fraud,
    roles.supervisorMidnight,
    roles.tech,
    roles.iot,
    roles.heads,
  ]
}

export default roles;
